const nitpush = [
  {
    path: '/login',
    name: 'auth-login',
    props:true,
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/kanal',
    name: 'kanal',
    component: () => import('@/views/channel/ChannelListView.vue'),
      meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
      layout: 'content',
    },
  },
  {
    path: '/kanal/erstellen',
    name: 'kanal_erstellen',
    component: () => import('@/views/channel/ChannelView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kanal/editieren/:idNipKanal(.*?)',
    name: 'kanal_editieren',
    props: true,
    component: () => import('@/views/channel/ChannelView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne',
    name: 'kampagne',
    component: () => import('@/views/campaign/CampaignListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/kampagne/erstellen',
    name: 'kampagne_erstellen',
    props: true,
    component: () => import('@/views/campaign/CampaignView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne/editieren/:idNipKampagne(.*?)',
    name: 'kampagne_editieren',
    props: true,
    component: () => import('@/views/campaign/CampaignView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/segment',
    name: 'segment',
    component: () => import('@/views/segment/SegmentListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/segment/erstellen',
    name: 'segment_erstellen',
    props: true,
    component: () => import('@/views/segment/SegmentView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/segment/editieren/:idNipSegment(.*?)',
    name: 'segment_editieren',
    props: true,
    component: () => import('@/views/segment/SegmentView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tag',
    name: 'tag',
    component: () => import('@/views/tag/TagListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tag/erstellen',
    name: 'tag_erstellen',
    props: true,
    component: () => import('@/views/tag/TagView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tag/editieren/:idNipTag(.*?)',
    name: 'tag_editieren',
    props: true,
    component: () => import('@/views/tag/TagView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/themenbereich',
    name: 'topic',
    component: () => import('@/views/topic/TopicListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/themenbereich/erstellen',
    name: 'themenbereich_erstellen',
    props: true,
    component: () => import('@/views/topic/TopicView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/themenbereich/editieren/:idNipThemenbereich(.*?)',
    name: 'themenbereich_editieren',
    props: true,
    component: () => import('@/views/topic/TopicView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/attribute',
    name: 'attribute',
    component: () => import('@/views/attribute/AttributeListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/event',
    name: 'event',
    component: () => import('@/views/event/EventListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/event/erstellen',
    name: 'event_erstellen',
    props: true,
    component: () => import('@/views/event/EventView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/event/editieren/:idNipEvent(.*?)',
    name: 'event_editieren',
    props: true,
    component: () => import('@/views/event/EventView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/passwort_vergessen',
    name: 'passwort_vergessen',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_zuruecksetzen/:hashKey(.*?)',
    name: 'passwort_zuruecksetzen',
    props: true,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_neu/:hashKey(.*?)',
    name: 'passwort_neu',
    props: true,
    component: () => import('@/views/NewPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/entsperrung/:hashKey(.*?)',
    name: 'entsperrung',
    props: true,
    component: () => import('@/views/UnlockLogin.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/aktivierung/:hashKey(.*?)',
    name: 'aktivierung',
    props: true,
    component: () => import('@/views/Activation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/konto',
    name: 'konto',
    component: () => import('@/views/Account.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter',
    name: 'mitarbeiter',
    component: () => import('@/views/EmployeeListView.vue'),
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/mitarbeiter/editieren/:idThHeadhunterMitarbeiter(.*?)',
    name: 'mitarbeiter_editieren',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter/erstellen',
    name: 'mitarbeiter_erstellen',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apizugang',
    name: 'apizugang',
    component: () => import('@/views/apiaccess/ApiAccessListView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default nitpush;
