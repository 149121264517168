<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.email}}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <template v-if="userData.accountType == 'employee'" >
        <v-list-item :to="'/mitarbeiter/editieren/'+userData.idNipKundeMitarbeiter">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Konto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </template>

      <template v-else>
        <v-list-item :to="{ name: 'konto', params: { id: userData.idNipKunde } }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Konto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'mitarbeiter', params: { id: userData.idNipKunde } }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountSupervisor }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mitarbeiter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'apizugang', params: { id: userData.idNipKunde } }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountCog }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Api-Zugänge</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>


      <!--      <v-divider class="my-2"></v-divider>

            <v-list-item :to="{ name: 'page-pricing' }">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiCurrencyUsd }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Preisliste</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'page-faq' }">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>FAQ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->

      <v-divider class="my-2"></v-divider>

      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiAccountCog,
  mdiAccountSupervisor,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import themeConfig from "@themeConfig";
import axios from "@axios";
import {canNavigate} from "@/plugins/acl/routeProtection";

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      axios.post('/api/logout/')
        .then((res) => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userData')
          localStorage.removeItem('userAbility')
          vm.$ability.update(initialAbility)
          router.push({ name: 'auth-login' },()=>{
            vm.$toastr.s('Sie haben sich erfolgreich ausgeloggt.', 'Benutzer ausgeloggt!');
          })
        })
        .catch((error) => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userData')
          localStorage.removeItem('userAbility')
          vm.$ability.update(initialAbility)
          router.push({ name: 'auth-login' },()=>{
            vm.$toastr.s('Es ist ein Fehler beim ausloggen aufgetreten.', 'Fehlgeschlagen!');
          })
        })
    }

    return {
      logoutUser,
      userData,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiAccountCog,
        mdiAccountSupervisor,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
